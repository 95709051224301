import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';
import Axios from './axios';
import Dashboard from './dashboard';
import Ecommerce from './ecommerce';
import Features from './features';
import Firestore from './firestore';
import Gallery from './gallery';
import Pages from './pages';
import Users from './users';
import Widgets from './widgets';
import Logo from '../../static/img/LogoWeb.png';
import { useChatStore } from '../../store/chatStore';
import { currentUser, isAdmin } from '../../utility/utility';
import { io } from 'socket.io-client';
import { API_ENDPOINT } from '../../config/dataService/dataService';
import toast from 'react-hot-toast';
import sound from '../../static/audio/notification.mp3';
import { Avatar, Button } from 'antd';

const Charts = lazy(() => import('./charts'));
const KnowledgeBase = lazy(() => import('../../container/pages/knowledgeBase/Index'));
const AllArticle = lazy(() => import('../../container/pages/knowledgeBase/AllArticle'));
const KnowledgeSingle = lazy(() => import('../../container/pages/knowledgeBase/SingleKnowledge'));
const Components = lazy(() => import('./components'));
const Task = lazy(() => import('../../container/task/Index'));
const Tickets = lazy(() => import('../../container/supportTicket/Index'));
const AddTicket = lazy(() => import('../../container/supportTicket/AddSupport'));
const TicketDetails = lazy(() => import('../../container/supportTicket/SupportTicketDetails'));
const Courses = lazy(() => import('../../container/course/Index'));
const CourseDetails = lazy(() => import('../../container/course/CourseDetails'));
const Contact = lazy(() => import('../../container/contact/Contact'));
const ContactGrid = lazy(() => import('../../container/contact/ContactGrid'));
const ContactAddNew = lazy(() => import('../../container/contact/AddNew'));
const Calendars = lazy(() => import('../../container/calendar/Calendar'));
const Import = lazy(() => import('../../container/importExport/Import'));
const Export = lazy(() => import('../../container/importExport/Export'));
const ToDo = lazy(() => import('../../container/toDo/ToDo'));
const Note = lazy(() => import('../../container/note/Note'));
const Projects = lazy(() => import('./projects'));
const Company = lazy(() => import('./company'));
const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));
const Chat = lazy(() => import('../../container/chat/ChatApp'));
const Inbox = lazy(() => import('../../container/email/Email'));
const Maps = lazy(() => import('./maps'));
const Editors = lazy(() => import('../../container/pages/Editor'));
const Icons = lazy(() => import('./icons'));
const Tables = lazy(() => import('./table'));
const Jobs = lazy(() => import('../../container/jobSearch/Jobs'));
const JobDetails = lazy(() => import('../../container/jobSearch/JobSearchDetails'));
const JobApply = lazy(() => import('../../container/jobSearch/JobApplication'));
const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const socket = useRef(null);
  console.debug('🚀 ~ file: index.js:55 ~ Admin ~ socket:', socket);
  const { pathname } = useLocation();
  const { setSocket, setIncommingUser, setIncommingGroup } = useChatStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    // Connect to the Socket.IO server
    socket.current = io(API_ENDPOINT);

    // // Emit an event to associate the user with the socket;
    // socket.current.on('connect', () => {
    //   console.debug('🚀 ~ Socket connected:', socket.current);

    //   // Emit an event to associate the user with the socket
    //   socket.current.emit('associateUser', currentUser()?._id);
    //   setSocket(socket.current);
    // });

    // // Cleanup function to disconnect when the component unmounts
    // return () => {
    //   // Disconnect the socket
    //   socket.current.disconnect();
    // };

    socket.current.on('connect', () => {
      console.debug('🚀 ~ Socket connected:', socket.current);

      // Emit an event to associate the user with the socket
      socket.current.emit('associateUser', currentUser()?._id);
      setSocket(socket.current);

      // Attach the event listener for new messages
      const handleNewMessage = (message) => {
        console.debug('🚀 ~ handleNewMessage ~ message:', message);
        if (message?.senderId === currentUser()?._id) return;

        setIncommingUser(message?.senderId);
        setIncommingGroup(message?.roomId);
        showNotification(message);
        handleNoti();
      };
      socket.current.on('newMessage', handleNewMessage);

      // Cleanup function for disconnect and reconnect
      socket.current.on('disconnect', () => {
        console.debug('🚀 ~ Socket disconnected:', socket.current);
        socket.current.off('newMessage', handleNewMessage);
      });

      // Also handle reconnect
      socket.current.on('reconnect', () => {
        console.debug('🚀 ~ Socket reconnected:', socket.current);
        socket.current.emit('associateUser', currentUser()?._id);
        socket.current.on('newMessage', handleNewMessage);
      });
    });

    // Cleanup function to disconnect when the component unmounts
    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);

  const showNotification = (message) => {
    toast.custom((t) => (
      <div
        className={`${
          t.visible ? 'animate-enter' : 'animate-leave'
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-start">
            {/* <div className="flex-shrink-0 pt-0.5"> */}
            {/* <img className="h-10 w-10 rounded-full bg-gray-400" src={Logo} alt="" /> */}
            <Avatar className="mx-2 mb-0">
              {(message?.senderRole === 'Manager' && !isAdmin() ? 'Estimating Manager' : message?.senderName)
                ?.charAt(0)
                .toUpperCase()}
            </Avatar>
            {/* </div> */}
            <div className="w-10/12 ml-3 flex-1">
              <p className="text-lg font-medium text-gray-900">
                {message?.senderRole === 'Admin'
                  ? 'Kashan Baig'
                  : message?.senderRole === 'Manager' && !isAdmin()
                  ? 'Estimating Manager'
                  : message?.senderName}
              </p>
              <p className="text-base mt-[-10px] w-full text-wrap break-words text-gray-500">
                {message?.text?.length > 80 ? message?.text?.substring(0, 80) + '...' : message?.text}
              </p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Close
          </button>
        </div>
      </div>
    ));
  };

  // useEffect(() => {
  //   // Listen for new messages
  //   console.debug('🚀 ~ file: index.js:114 ~ useEffect ~ socket.current:', socket.current);
  //   if (socket.current && socket.current.connected) {
  //     const handleNewMessage = (message) => {
  //       console.debug('🚀 ~ file: index.js:117 ~ handleNewMessage ~ message:', message);
  //       showNotification(message);
  //       handleNoti();
  //     };

  //     // Attach the event listener
  //     socket.current.on('newMessage', handleNewMessage);
  //   }
  // }, [socket.current]);

  const handleNoti = () => {
    const audio = new Audio(sound);
    audio.play().catch((error) => {
      console.error('Audio playback failed:', error);
    });
  };

  return (
    <Suspense
      fallback={
        <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] ltr:left-0 rtl:right-0 top-0">
          <img src={Logo} className="animate-ping w-[200px]" />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Dashboard />} />
        <Route path="pages/*" element={<Pages />} />
        <Route path="gallery/*" element={<Gallery />} />
        <Route path="all-articles" element={<AllArticle />} />
        <Route path="knowledgeBase/*" element={<KnowledgeBase />} />
        <Route path="knowledgebaseSingle/:id" element={<KnowledgeSingle />} />
        <Route path="components/*" element={<Components />} />
        <Route path="charts/*" element={<Charts />} />
        <Route path="app/task/*" element={<Task />} />
        <Route path="users/*" element={<Users />} />
        <Route path="app/support/tickets/*" element={<Tickets />} />
        <Route path="app/support/tickets/add" element={<AddTicket />} />
        <Route path="app/support/ticketDetails/:id" element={<TicketDetails />} />
        <Route path="app/course/courseDetails/:id" element={<CourseDetails />} />
        <Route path="app/course/*" element={<Courses />} />
        <Route path="contact/list" element={<Contact />} />
        <Route path="contact/grid" element={<ContactGrid />} />
        <Route path="contact/addNew" element={<ContactAddNew />} />
        <Route path="app/calendar/*" element={<Calendars />} />
        <Route path="importExport/import" element={<Import />} />
        <Route path="importExport/export" element={<Export />} />
        <Route path="app/to-do" element={<ToDo />} />
        <Route path="app/note/*" element={<Note />} />
        <Route path="features/*" element={<Features />} />
        <Route path="axios/*" element={<Axios />} />
        <Route path="firestore/*" element={<Firestore />} />
        <Route path="projects/*" element={<Projects />} />
        <Route path="company/*" element={<Company />} />
        <Route path="profile/myProfile/*" element={<Myprofile />} />
        <Route path="create-invoice/*" element={<Ecommerce />} />
        <Route path="main/chat/*" element={<Chat />} />
        <Route path="email/*" element={<Inbox />} />
        <Route path="maps/*" element={<Maps />} />
        <Route path="editor" element={<Editors />} />
        <Route path="icons/*" element={<Icons />} />
        <Route path="tables/*" element={<Tables />} />
        <Route path="app/jobs/*" element={<Jobs />} />
        <Route path="app/job/apply" element={<JobApply />} />
        <Route path="app/jobDetails/:id" element={<JobDetails />} />
        <Route path="widgets/*" element={<Widgets />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
