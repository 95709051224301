import { Dropdown } from 'antd';
import Styled from 'styled-components';

const Content = Styled.div`
    
`;

const DropdownStyle = Styled(Dropdown)`

`;

export { Content, DropdownStyle };
