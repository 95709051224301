import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { currentUser, isAdmin } from '../../utility/utility';

const Product = lazy(() => import('../../container/ecommerce/product/Products'));
const ProductAdd = lazy(() => import('../../container/ecommerce/product/AddProduct'));
const ProductEdit = lazy(() => import('../../container/ecommerce/product/EditProduct'));
const ProductDetails = lazy(() => import('../../container/ecommerce/product/ProductDetails'));
const Invoice = lazy(() => import('../../container/ecommerce/Invoice'));
const Orders = lazy(() => import('../../container/ecommerce/Orders'));
const ViewInvoices = lazy(() => import('../../container/ecommerce/ViewInvoices'));
const Sellers = lazy(() => import('../../container/ecommerce/Sellers'));
const Cart = lazy(() => import('../../container/ecommerce/Cart'));
const Checkout = lazy(() => import('../../container/ecommerce/Checkout'));
const NotFound = lazy(() => import('../../container/pages/404'));

function EcommerceRoute() {
  const isChildAdmin = currentUser()?.companyId?.members?.some(
    (a) => a.user === currentUser()?._id && a.role === 'admin',
  );

  return (
    <Routes>
      <Route path="products/*" element={<Product />} />
      <Route exact path="add-product" element={<ProductAdd />} />
      <Route exact path="edit-product" element={<ProductEdit />} />
      <Route exact path="productDetails/:id" element={<ProductDetails />} />
      <Route exact path="invoice" element={<Invoice />} />
      {isAdmin() && <Route exact path="projects" element={<Orders />} />}
      {(isAdmin() ||
        (currentUser()?.role === 'Client' && currentUser()?.companyId?.companyType === 'PARENT') ||
        (currentUser()?.role === 'Client' && currentUser()?.companyId?.companyType === 'SINGLE') ||
        (currentUser()?.role === 'Client' && !currentUser()?.companyId?.companyType) ||
        (currentUser()?.companyId?.companyType === 'CHILD' && isChildAdmin)
      ) && (
        <Route exact path="view-invoices" element={<ViewInvoices />} />
      )}
      <Route exact path="sellers" element={<Sellers />} />
      <Route path="cart/*" element={<Cart />} />
      <Route path="checkout/*" element={<Checkout />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default EcommerceRoute;
