export const services = [
  {
    label: 'New Takeoff',
    value: 'new_takeoff',
  },
  {
    label: 'Addendum',
    value: 'addendum',
  },
];

export const segment = [
  {
    label: 'Airports / Infrastructure',
    value: 'airports_infrastructure',
  },
  {
    label: 'Athletic Facilities',
    value: 'athletic_facilities',
  },
  {
    label: 'Commercial',
    value: 'commercial',
  },
  {
    label: 'Education',
    value: 'education',
  },
  {
    label: 'Education-Dormitories',
    value: 'education_dormitories',
  },
  {
    label: 'Facility Service',
    value: 'facility_service',
  },
  {
    label: 'Government',
    value: 'government',
  },
  {
    label: 'Healthcare',
    value: 'healthcare',
  },
  {
    label: 'Hospitality',
    value: 'hospitality',
  },
  {
    label: 'Multi-Family / Use',
    value: 'multifamily_use',
  },
  {
    label: 'Restaurant',
    value: 'restaurant',
  },
  {
    label: 'Retail',
    value: 'retail',
  },
  {
    label: 'Senior Living',
    value: 'senior_living',
  },
  {
    label: 'Special Facility',
    value: 'special_facility',
  },
  {
    label: 'Tenant Improvement',
    value: 'tenant_improvement',
  },
];

export const projectSize = [
  {
    label: '<20k (under 20k)',
    value: 'less_then_20k',
  },
  {
    label: '20k - 50k',
    value: '20to50k',
  },
  {
    label: '>50k (over 50k)',
    value: 'over50k',
  },
  {
    label: '>100k (over 100k)',
    value: 'over100k',
  },
  {
    label: '>200k (over 200k)',
    value: 'over200k',
  },
  {
    label: '>500k (over 500k)',
    value: 'over500k',
  },
];

export const materials = [
  { label: 'Access Flooring', value: 'access_flooring' },
  { label: 'Altro Whiterock', value: 'altro_whiterock' },
  { label: 'Area Rug', value: 'area_rug' },
  { label: 'Carpet Pad', value: 'carpet_pad' },
  { label: 'Carpet Tile', value: 'carpet_tile' },
  { label: 'Carpet, Broadloom', value: 'carpet_broadloom' },
  { label: 'Concrete', value: 'concrete' },
  { label: 'Demo', value: 'demo' },
  { label: 'Entry Mat', value: 'entry_met' },
  { label: 'Floor Prep', value: 'floor_prep' },
  { label: 'Floor Protection', value: 'floor_protection' },
  { label: 'Gypcrete', value: 'gypcrete' },
  { label: 'Hardwood', value: 'hardwood' },
  { label: 'Inpro Wall Cladding', value: 'inpro_wall' },
  { label: 'Mud Bed', value: 'mud_bed' },
  { label: 'ProtectAll', value: 'protectall' },
  { label: 'Resinous (Epoxy) Base', value: 'resinous_base' },
  { label: 'Resinous (Epoxy) Flooring', value: 'resinous_flooring' },
  { label: 'Rubber Sheet', value: 'rubber_sheet' },
  { label: 'Rubber Tile', value: 'rubber_tile' },
  { label: 'Sheet Vinyl', value: 'sheet_vinyl' },
  { label: 'Slabs / Panels', value: 'slabs' },
  { label: 'Stair Nosing', value: 'stair_nosing' },
  { label: 'Stair Stringer', value: 'stair_stringer' },
  { label: 'Stair Tread', value: 'stair_tread' },
  { label: 'Stone', value: 'stone' },
  { label: 'Tack Strip', value: 'tack_strip' },
  { label: 'Terrazzo (Poured) Base', value: 'terrazzo_base' },
  { label: 'Terrazzo (Poured) Flooring', value: 'terrazzo_flooring' },
  { label: 'Terrazzo Tile', value: 'terrazzo_tile' },
  { label: 'Thin Brick', value: 'thin_brick' },
  { label: 'Tile', value: 'tile' },
  { label: 'Transition', value: 'transition' },
  { label: 'Trim', value: 'trim' },
  { label: 'Underlayment (Acoustical/Crack Iso/Waterproofing', value: 'underlayment' },
  { label: 'Vinyl Tile', value: 'vinyl_tile' },
  { label: 'Wall Base', value: 'wall_base' },
  { label: 'Wallcovering', value: 'wallcovering' },
  { label: 'Window Sills', value: 'window_sills' },
];

// Company Columns Based on Contract Types
// Base columns
const protectedCompanyTableColumns = [
  {
    title: 'Name',
    dataIndex: 'company',
    key: 'company',
  },
  {
    title: 'Trade',
    dataIndex: 'trade',
    key: 'trade',
  },
  {
    title: 'Scope',
    dataIndex: 'scope',
    key: 'scope',
  },
  {
    title: 'Contract Type',
    dataIndex: 'contractType',
    key: 'contractType',
  },
  {
    title: 'Software',
    dataIndex: 'software',
    key: 'software',
    width: '90px',
  },
];

// Additional columns based on contract type
export const hourlyColumns = [
  {
    title: 'Hourly Rate',
    dataIndex: 'hourlyRate',
    key: 'hourlyRate',
  },
];

export const projectBasedColumns = [
  {
    title: 'Per Project Charge',
    dataIndex: 'perProjectCharge',
    key: 'perProjectCharge',
  },
  {
    title: 'Average Project Size',
    dataIndex: 'averageProjectSize',
    key: 'averageProjectSize',
  },
];

export const perSqftColumns = [
  {
    title: 'Sqft Rate',
    dataIndex: 'sqftRate',
    key: 'sqftRate',
  },
];

export const monthlySqftContractColumns = [
  {
    title: 'Monthly Fixed Charge',
    dataIndex: 'monthlyFixedCharge',
    key: 'monthlyFixedCharge',
  },
  {
    title: 'Monthly Sqft Limit',
    dataIndex: 'monthlySqftLimit',
    key: 'monthlySqftLimit',
  },
  {
    title: 'Exceeded Per Sqft Rate',
    dataIndex: 'exceededPerSqftRate',
    key: 'exceededPerSqftRate',
  },
];

export const yearlySqftContractColumns = [
  {
    title: 'Monthly Fixed Charge',
    dataIndex: 'monthlyFixedCharge',
    key: 'monthlyFixedCharge',
  },
  {
    title: 'Yearly Sqft Limit',
    dataIndex: 'yearlySqftLimit',
    key: 'yearlySqftLimit',
  },
  {
    title: 'Exceeded Per Sqft Rate',
    dataIndex: 'exceededPerSqftRate',
    key: 'exceededPerSqftRate',
  },
  {
    title: 'Annual Contract Start Date',
    dataIndex: 'annualContractStartDate',
    key: 'annualContractStartDate',
  },
  {
    title: 'Annual Contract End Date',
    dataIndex: 'annualContractEndDate',
    key: 'annualContractEndDate',
  },
];
