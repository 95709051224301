import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import React, { lazy, useEffect, useRef, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider, useSelector } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import 'react-intl-tel-input/dist/main.css';
import 'antd/dist/antd.less';

const UserDetail = lazy(() => import('./container/profile/authentication/overview/UserDetail'));

import { useChatStore } from './store/chatStore';
import { currentUser } from './utility/utility';
import AuthLayout from './container/profile/authentication/Index';

const NotFound = lazy(() => import('./container/pages/404'));

const { theme } = config;

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  // const history = useNavigate();

  // useEffect(() => {
  //   if (!currentUser()?.companyId) {
  //     history('/verify', { replace: true });
  //   }
  // }, [currentUser]);

  const VerifyRoutes = () => {
    return (
      <Routes>
        <Route index element={<UserDetail />} />
        <Route path="verify" element={<UserDetail />} />
        <Route path="/dashboard" element={<Navigate to="/verify" />} />
      </Routes>
    );
  };

  const WithLayout = AuthLayout(VerifyRoutes);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, mainContent }}>
        <Toaster position="top-right" />
        <Router basename={process.env.PUBLIC_URL}>
          {currentUser() && !currentUser()?.companyId ? (
            <WithLayout />
          ) : !isLoggedIn ? (
            <Routes>
              <Route path="/*" element={<Auth />} />{' '}
            </Routes>
          ) : (
            <Routes>
              <Route path="/dashboard/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
          {/* {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
            </Routes>
          )} */}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ProviderConfig />
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
