import { message } from 'antd';
import { format } from 'date-fns';
import { Dropbox } from 'dropbox';
import Cookies from 'js-cookie';
import {
  hourlyColumns,
  monthlySqftContractColumns,
  perSqftColumns,
  projectBasedColumns,
  yearlySqftContractColumns,
} from './projectDataValues';
import moment from 'moment/moment';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length ? `${'0'.repeat(length - number.length)}${number}` : number;
};

const API_URL = process.env.REACT_APP_BASE_URL || '';

const projectStatus = {
  NOT_STARTED: 'Not Started',
  PROGRESS: 'Progress',
  ON_HOLD: 'On Hold',
  COMPLETED: 'Completed',
  DISREGARD: 'Disregard',
  PAYMENT_PENDING: 'Payment Pending',
  PAYMENT_COMPLETED: 'Payment Completed',
};

const isCustomBusinessEmail = (email = '') => {
  // List of common email providers
  const commonProviders = ['gmail.com', 'hotmail.com', 'outlook.com', 'yahoo.com'];

  // Extract the domain from the email
  const emailDomain = email.split('@')[1];

  // Check if the email domain is not in the list of common providers
  return !commonProviders.includes(emailDomain);
};

const currentUser = () => (Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null);
const accessToken = () => (Cookies.get('access_token') ? Cookies.get('access_token') : null);

const isAdmin = () => {
  return currentUser().role === 'Admin';
};

const isManager = () => {
  return currentUser().role === 'Manager';
};

const formatDate = (inputDate) => {
  const parsedDate = new Date(inputDate);
  const formattedDate = format(parsedDate, 'dd MMMM, yyyy');
  return formattedDate;
};

const formatDateTime = (inputDate) => {
  const parsedDate = new Date(inputDate);
  const formattedDate = format(parsedDate, 'MMMM dd, yyyy hh:mm:ss a');
  return formattedDate;
};

async function uploadFileToDropbox(path, file, onProgress, newAccessToken) {
  const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
  const ACCESS_TOKEN = newAccessToken;
  const dbx = new Dropbox({ accessToken: ACCESS_TOKEN });

  try {
    let response;
    if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
      // File is smaller than 150 MB - use filesUpload API
      response = await dbx.filesUpload({ path, contents: file });
      onProgress(100); // Directly set progress to 100% for small files
    } else {
      // File is bigger than 150 MB - use filesUploadSession* API
      const maxBlob = 12 * 1024 * 1024; // 12MB - Dropbox JavaScript API suggested chunk size
      const workItems = [];
      let offset = 0;

      while (offset < file.size) {
        const chunkSize = Math.min(maxBlob, file.size - offset);
        workItems.push(file.slice(offset, offset + chunkSize));
        offset += chunkSize;
      }

      let uploadedBytes = 0;
      let totalBytes = file.size;

      let sessionId;
      for (let idx = 0; idx < workItems.length; idx++) {
        const chunk = workItems[idx];
        if (idx === 0) {
          // Start multipart upload of file
          const startResponse = await dbx.filesUploadSessionStart({ close: false, contents: chunk });
          sessionId = startResponse.result.session_id;
        } else if (idx < workItems.length - 1) {
          // Append part to the upload session
          await dbx.filesUploadSessionAppendV2({
            cursor: { session_id: sessionId, offset: idx * maxBlob },
            close: false,
            contents: chunk,
          });
        } else {
          // Last chunk of data, close session
          const finishResponse = await dbx.filesUploadSessionFinish({
            cursor: { session_id: sessionId, offset: file.size - chunk.size },
            commit: { path, mode: 'add', autorename: true, mute: false },
            contents: chunk,
          });
          response = finishResponse;
        }

        uploadedBytes += chunk.size;
        const progress = Math.round((uploadedBytes / totalBytes) * 100);
        onProgress(progress);
      }
    }

    // Return the file upload response
    return response;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

async function createDropboxFolder(path, newAccessToken) {
  const ACCESS_TOKEN = newAccessToken;
  const dbx = new Dropbox({ accessToken: ACCESS_TOKEN });

  try {
    const response = await dbx.filesCreateFolderV2({ path });
    return response;
  } catch (error) {
    // Handle folder already exists error
    if (error.error && error.error.path && error.error.path['.tag'] === 'conflict') {
      console.warn(`Folder ${path} already exists.`);
      return null;
    } else {
      console.error('Error creating folder:', error);
      throw error;
    }
  }
}

async function createDropboxSharedLink(path, newAccessToken) {
  const ACCESS_TOKEN = newAccessToken;
  const dbx = new Dropbox({ accessToken: ACCESS_TOKEN });

  try {
    const sharedLinkResponse = await dbx.sharingCreateSharedLink({ path });
    return sharedLinkResponse;
  } catch (error) {
    console.error('Error creating shared link:', error);
    throw error;
  }
}

const contractTypeToNormalCase = (type) => {
  switch (type) {
    case 'HOURLY':
      return 'Hourly';
    case 'PROJECT_BASED':
      return 'Project Based';
    case 'PER_SQFT':
      return 'Per Sqft';
    case 'MONTHLY_SQFT_CONTRACT':
      return 'Monthly Sqft Contract';
    case 'YEARLY_SQFT_CONTRACT':
      return 'Yearly Sqft Contract';
    default:
      return type;
  }
};

// Function to get columns based on contract type
const getCompanyTableColumns = (contractType, protectedCompanyTableColumns = []) => {
  switch (contractType) {
    case 'HOURLY':
      return [...protectedCompanyTableColumns, ...hourlyColumns];
    case 'PROJECT_BASED':
      return [...protectedCompanyTableColumns, ...projectBasedColumns];
    case 'PER_SQFT':
      return [...protectedCompanyTableColumns, ...perSqftColumns];
    case 'MONTHLY_SQFT_CONTRACT':
      return [...protectedCompanyTableColumns, ...monthlySqftContractColumns];
    case 'YEARLY_SQFT_CONTRACT':
      return [...protectedCompanyTableColumns, ...yearlySqftContractColumns];
    default:
      return protectedCompanyTableColumns;
  }
};

// Function to get contract type-specific data
const getContractTypeSpecificData = (company) => {
  const {
    contractType,
    hourlyRate,
    perProjectCharge,
    averageProjectSize,
    sqftRate,
    monthlyFixedCharge,
    monthlySqftLimit,
    exceededPerSqftRate,
    yearlySqftLimit,
    annualContractStartDate,
    annualContractEndDate,
  } = company;

  switch (contractType) {
    case 'HOURLY':
      return {
        hourlyRate: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            ${hourlyRate?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 'N/A'}
          </span>
        ),
      };

    case 'PROJECT_BASED':
      return {
        perProjectCharge: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            ${perProjectCharge?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 'N/A'}
          </span>
        ),
        averageProjectSize: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            {averageProjectSize?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 'N/A'}
          </span>
        ),
      };

    case 'PER_SQFT':
      return {
        sqftRate: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            ${sqftRate?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 'N/A'}
          </span>
        ),
      };

    case 'MONTHLY_SQFT_CONTRACT':
      return {
        monthlyFixedCharge: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            ${monthlyFixedCharge?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 'N/A'}
          </span>
        ),
        monthlySqftLimit: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            {monthlySqftLimit?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 'N/A'}
          </span>
        ),
        exceededPerSqftRate: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            ${exceededPerSqftRate?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 'N/A'}
          </span>
        ),
      };

    case 'YEARLY_SQFT_CONTRACT':
      return {
        monthlyFixedCharge: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            ${monthlyFixedCharge?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 'N/A'}
          </span>
        ),
        yearlySqftLimit: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            {yearlySqftLimit?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 'N/A'}
          </span>
        ),
        exceededPerSqftRate: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            ${exceededPerSqftRate?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 'N/A'}
          </span>
        ),
        annualContractStartDate: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            {moment(annualContractStartDate).format('MMMM Do YYYY') ?? 'N/A'}
          </span>
        ),
        annualContractEndDate: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            {moment(annualContractEndDate).format('MMMM Do YYYY') ?? 'N/A'}
          </span>
        ),
      };

    default:
      return {};
  }
};

export {
  ellipsis,
  idGenerator,
  API_URL,
  projectStatus,
  currentUser,
  accessToken,
  isCustomBusinessEmail,
  isAdmin,
  isManager,
  formatDate,
  formatDateTime,
  uploadFileToDropbox,
  createDropboxFolder,
  createDropboxSharedLink,
  contractTypeToNormalCase,
  getCompanyTableColumns,
  getContractTypeSpecificData,
};
