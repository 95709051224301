import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { create } from 'zustand';

export const useUserStore = create((set) => ({
  userId: '',
  company: null,
  user: null,
  role: '',
  setUserId: (userId) => set(() => ({ userId })),
  setCompany: (company) => set(() => ({ company })),
  setUser: (user) => set(() => ({ user })),
  setRole: (role) => set(() => ({ role })),
}));

const { loginBegin, signupBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, stopLoading } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const payload = {
        email: values.email.toLocaleLowerCase(),
        password: values.password,
      };
      const response = await DataService.post('/auth/login', payload);
      if (response.data.errors) {
        dispatch(loginErr(response.data.errors));
      } else {
        Cookies.set('user', response.data.user);
        Cookies.set('access_token', response.data.token);
        Cookies.set('logedIn', true);
        dispatch(loginSuccess(true));
        callback(response.data);
      }
    } catch (err) {
      dispatch(loginErr(err.message));
      dispatch(stopLoading());
    }
  };
};
const register = (values, callback) => {
  return async (dispatch) => {
    dispatch(signupBegin());
    try {
      const payload = {
        email: values.email.toLocaleLowerCase(),
        password: values.password,
      };
      if (values?.isInvited) payload.isInvited = true;
      const response = await DataService.post('/auth/signup', payload);

      if (response.status === 203) {
        dispatch(loginErr('User already exists!'));
        dispatch(stopLoading());
        return;
      }

      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        callback(response.data);
        dispatch(stopLoading());
      }
    } catch (err) {
      dispatch(loginErr(typeof err === 'string' ? err : err?.message));
      dispatch(stopLoading());
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      Cookies.remove('user');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
      dispatch(stopLoading());
    }
  };
};

export { login, logOut, register };
