import Styled from 'styled-components';

const InfoWrapper = Styled.div`

`;

const SettingDropDown = Styled.div`

`;

const NestedDropDown = Styled.div`

`;

const UserDropDown = Styled.div`

`;

const UserActionDropDown = Styled.div`

`;

const NavAuth = Styled.span`
    
`;

export { InfoWrapper, SettingDropDown, NestedDropDown, UserDropDown, UserActionDropDown, NavAuth };
