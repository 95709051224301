import { create } from 'zustand';

export const useChatStore = create((set) => ({
  socket: null,
  selectedUser: null,
  setSelectedUser: (selectedUser) => set(() => ({ selectedUser })),
  setIncommingUser: (incommingUser) => set(() => ({ incommingUser })),
  setIncommingGroup: (incommingGroup) => set(() => ({ incommingGroup })),
  setSocket: (socket) => set(() => ({ socket })),
}));
