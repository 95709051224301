import {
  Uil500px,
  UilAirplay,
  UilArrowGrowth,
  UilAt,
  UilBagAlt,
  UilBookAlt,
  UilBookOpen,
  UilBookReader,
  UilCalendarAlt,
  UilChartBar,
  UilChat,
  UilCheckSquare,
  UilCircle,
  UilClipboardAlt,
  UilClock,
  UilCompactDisc,
  UilCreateDashboard,
  UilDatabase,
  UilDocumentLayoutLeft,
  UilEdit,
  UilEnvelope,
  UilExchange,
  UilExclamationOctagon,
  // UilExpandArrowsAlt,
  UilFile,
  UilFileShieldAlt,
  UilHeadphones,
  UilIcons,
  UilImages,
  UilLayerGroup,
  UilMap,
  UilPresentation,
  UilQuestionCircle,
  UilSearch,
  UilServer,
  UilSetting,
  UilShoppingCart,
  UilSquareFull,
  UilInvoice,
  UilTable,
  UilUsdCircle,
  UilUsersAlt,
  UilWindowSection,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';
import versions from '../demoData/changelog.json';
import { changeDirectionMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
import { currentUser, isAdmin, isManager } from '../utility/utility';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = '/dashboard';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };
  const changeNavbar = (topMode) => {
    const html = document.querySelector('html');
    if (topMode) {
      html.classList.add('hexadash-topmenu');
    } else {
      html.classList.remove('hexadash-topmenu');
    }
    dispatch(changeMenuMode(topMode));
  };
  const changeLayoutDirection = (rtlMode) => {
    if (rtlMode) {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'rtl');
    } else {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'ltr');
    }
    dispatch(changeDirectionMode(rtlMode));
  };

  const darkmodeActivated = () => {
    document.body.classList.add('dark');
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove('dark');
  };

  const items = [
    // getItem(t('dashboard'), 'dashboard', [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}`}>
        {t('Dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}`}>
          <UilCreateDashboard />
        </NavLink>
      ),
    ),
    // (
    isAdmin() &&
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
          {t('users')}
        </NavLink>,
        'users',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/users`}>
            <UilUsersAlt />
          </NavLink>
        ),
      ),

    getItem('Company', 'companies', !topMenu && <UilServer />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/company`}>
          Company Details
        </NavLink>,
        'company',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/company/member`}>
          Company Members
        </NavLink>,
        'members',
        null,
      ),
    ]),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/projects`}>
        {/* {t('project')} */}
        Projects
      </NavLink>,
      'project',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/projects`}>
          <UilBagAlt />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/main/chat/private`}>
        {t('chat')}
      </NavLink>,
      'chat',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/main/chat/private`}>
          <UilChat />
        </NavLink>
      ),
    ),

    // getItem(t('users'), 'users', !topMenu && <UilUsersAlt />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/team`}>
    //       {t('team')}
    //     </NavLink>,
    //     'team',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/grid`}>
    //       {t('users')} {t('grid')}
    //     </NavLink>,
    //     'user-grid',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/list`}>
    //       {t('users')} {t('list')}
    //     </NavLink>,
    //     'user-list',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/grid-style`}>
    //       {t('users')} {t('grid')} {t('style')}
    //     </NavLink>,
    //     'grid-style',
    //     null,
    //   ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/users/edit-user/info`}>
    //     {t('edit')} {t('user')}
    //   </NavLink>,
    //   'editUser',
    //   null,
    // ),

    // ]),

    // getItem(t('project'), 'project', !topMenu && <UilBagAlt />, [

    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/project/view/list`}>
    //       {t('project')} {t('list')}
    //     </NavLink>,
    //     'projectList',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/project/create`}>
    //       {t('create')} {t('project')}
    //     </NavLink>,
    //     'ProjectCreate',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/project/projectDetails/1/tasklist`}>
    //       {t('project')} {t('details')}
    //     </NavLink>,
    //     'projectDetails',
    //     null,
    //   ),
    // ]),
    !isManager() &&
      (!isAdmin()
        ? getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/create-invoice/view-invoices`}>
              Billing
            </NavLink>,
            'ViewInvoice',
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/create-invoice/view-invoices`}>
                <UilInvoice />
              </NavLink>
            ),
          )
        : getItem('Billing', 'ecommerce', !topMenu && <UilInvoice />, [
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/create-invoice/projects`}>
                Create Invoice
              </NavLink>,
              'orders',
              null,
            ),
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/create-invoice/view-invoices`}>
                View Invoices
              </NavLink>,
              'ViewInvoice',
              null,
            ),
          ])),
  ];
  const childItems = [
    // getItem(t('dashboard'), 'dashboard', [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}`}>
        {t('Dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}`}>
          <UilCreateDashboard />
        </NavLink>
      ),
    ),
    // (
    isAdmin() &&
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
          {t('users')}
        </NavLink>,
        'users',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/users`}>
            <UilUsersAlt />
          </NavLink>
        ),
      ),

    getItem('Company', 'companies', !topMenu && <UilServer />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/company`}>
          Company Details
        </NavLink>,
        'company',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/company/member`}>
          Company Members
        </NavLink>,
        'members',
        null,
      ),
    ]),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/projects`}>
        {/* {t('project')} */}
        Projects
      </NavLink>,
      'project',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/projects`}>
          <UilBagAlt />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/main/chat/private`}>
        {t('chat')}
      </NavLink>,
      'chat',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/main/chat/private`}>
          <UilChat />
        </NavLink>
      ),
    ),

    // getItem(t('users'), 'users', !topMenu && <UilUsersAlt />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/team`}>
    //       {t('team')}
    //     </NavLink>,
    //     'team',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/grid`}>
    //       {t('users')} {t('grid')}
    //     </NavLink>,
    //     'user-grid',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/list`}>
    //       {t('users')} {t('list')}
    //     </NavLink>,
    //     'user-list',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/grid-style`}>
    //       {t('users')} {t('grid')} {t('style')}
    //     </NavLink>,
    //     'grid-style',
    //     null,
    //   ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/users/edit-user/info`}>
    //     {t('edit')} {t('user')}
    //   </NavLink>,
    //   'editUser',
    //   null,
    // ),

    // ]),

    // getItem(t('project'), 'project', !topMenu && <UilBagAlt />, [

    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/project/view/list`}>
    //       {t('project')} {t('list')}
    //     </NavLink>,
    //     'projectList',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/project/create`}>
    //       {t('create')} {t('project')}
    //     </NavLink>,
    //     'ProjectCreate',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/project/projectDetails/1/tasklist`}>
    //       {t('project')} {t('details')}
    //     </NavLink>,
    //     'projectDetails',
    //     null,
    //   ),
    // ]),
  ];

  const isChildAdmin = currentUser()?.companyId?.members?.some(
    (a) => a.user === currentUser()?._id && a.role === 'admin',
  );
  console.debug('🚀 ~ file: MenueItems.js:425 ~ MenuItems ~ isChildAdmin:', isChildAdmin, currentUser());

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={currentUser()?.companyId?.companyType === 'CHILD' && !isChildAdmin ? childItems : items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
